.box {
  margin: 20px;
}

.headerText {
  font-weight: 700;
  margin: 10px;
}

.verticalBar {
  border-left: 3px solid black;
  height: 180px;
  width: 3px;
}

.mostRecentOvfSubmitText {
  padding-top: 50px;
}

.inlineSelectLocationsMenu {
  display: inline-block;
}
