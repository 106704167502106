table .locationHourInput {
  width: 80px;
}

table .taperPresetInput {
  width: 130px;
}

table .booleanInput {
  width: 80px;
}
