.componentLabel {
  font-weight: 700;
}

.dailyFlex {
  justify-content: left;
  min-width: 1100px;
  width: 100%;
  padding: 8px, 100px;
}

.dailyBox {
  margin: 10px, 0;
  justify-content: center;
  width: 100%;
}
