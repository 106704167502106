.evenTableRow {
  background-color: #edf2f7;
}

.oddTableRow {
  background-color: #fff;
}

.qaRunTimestampContainer {
  display: flex;
  justify-content: space-between;
}

.currentlyActiveDopplerRunText {
  display: block;
}

.popupLink {
  color: blue;
}

.selectCountry {
  margin-top: 10px;
}
