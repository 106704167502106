.tableHeader {
  display: inline-block;
}

.tableBody {
  display: block;
  height: 800px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: small;
}
